export interface EventTheme {
  active: boolean;
  colors: EventThemeColor[];
  logo: EventThemeLogo;
}

export interface EventThemeColor {
  name: TELLSY_COLOR_NAME;
  value: string;
}

export interface EventThemeLogo {
  fileId: string;
  fileName: string;
  originalFilename: string;
  show: boolean;
}

export type TELLSY_COLOR_NAME =
  | "primary"
  | "primary-dark"
  | "primary-light"
  | "primary-opaque"
  | "correct"
  | "warning"
  | "error"
  | "self-cons"
  | "pros"
  | "cons"
  | "disabled"
  | "frame"
  | "icon"
  | "text-primary"
  | "text-primary-participant"
  | "text-primary-dark"
  | "text-primary-dark-participant"
  | "text-disabled-participant"
  | "text-background"
  | "brainstorm-main"
  | "brainstorm-accent"
  | "primary-participant"
  | "primary-light-participant"
  | "primary-light-opaque-participant"
  | "primary-dark-participant"
  | "icon-participant"
  | "active-user"
  | "chart-primary"
  | "chart-background"
  | "chart-correct"
  | "chart-correct-opaque";

export const DEFAULT_TELLSY_THEME_COLORS: EventThemeColor[] = [
  {
    name: "primary",
    value: "#7262cc",
  },
  {
    name: "primary-dark",
    value: "#201367",
  },
  {
    name: "primary-light",
    value: "#cfcaed",
  },
  {
    name: "primary-opaque",
    value: "#7262cc99",
  },
  {
    name: "correct",
    value: "#54d92b",
  },
  {
    name: "warning",
    value: "#ffad05",
  },
  {
    name: "error",
    value: "#f44336",
  },
  {
    name: "self-cons",
    value: "rgba(243,123,115)",
  },
  {
    name: "pros",
    value: "rgba(84,217,43,0.6)",
  },
  {
    name: "cons",
    value: "rgba(243,123,115,0.7)",
  },
  {
    name: "disabled",
    value: "#9e9dab",
  },
  {
    name: "frame",
    value: "#d6d6d9",
  },
  {
    name: "text-primary",
    value: "#424258",
  },
  {
    name: "text-primary-dark",
    value: "#251e46",
  },
  {
    name: "text-background",
    value: "#ffffff",
  },
  {
    name: "active-user",
    value: "#48ae48",
  },
  {
    name: "icon",
    value: "#9e9dab",
  },

  // participant
  {
    name: "text-primary-participant",
    value: "#4a4a6c",
  },
  {
    name: "text-primary-dark-participant",
    value: "#2b254e",
  },
  {
    name: "text-disabled-participant",
    value: "#9e9dab",
  },
  {
    name: "primary-participant",
    value: "#3e00ea",
  },
  {
    name: "primary-light-participant",
    value: "#8242ff",
  },
  {
    name: "primary-light-opaque-participant",
    value: "#8242ff14",
  },
  {
    name: "primary-dark-participant",
    value: "#00009f",
  },
  {
    name: "icon-participant",
    value: "#6a6aa0",
  },

  // brainstorm
  {
    name: "brainstorm-main",
    value: "#2667ff",
  },
  {
    name: "brainstorm-accent",
    value: "#ffad05",
  },

  // chart
  {
    name: "chart-primary",
    value: "#4027ce",
  },
  {
    name: "chart-background",
    value: "#a97dff14",
  },
  {
    name: "chart-correct",
    value: "#54d92b",
  },
  {
    name: "chart-correct-opaque",
    value: "#54d92b14",
  },
];

export const DEFAULT_TELLSY_THEME: EventTheme = {
  active: false,
  colors: DEFAULT_TELLSY_THEME_COLORS,
  logo: null,
};

export interface EventThemeColorDescription {
  label: string;
}

export const EVENT_THEME_COLOR_DESCRIPTIONS: Record<
  TELLSY_COLOR_NAME,
  EventThemeColorDescription
> = {
  "primary": { label: "Основной цвет" },
  "primary-dark": { label: "Основной цвет - тёмный" },
  "primary-light": { label: "Основной цвет - светлый" },
  "primary-opaque": { label: "Основной цвет - полупрозрачный" },
  "correct": {
    label:
      "Правильный (Сообщение об успешном действии, цвет выбранного места в группе для участника, правильный ответ в графиках квиза)",
  },
  "warning": { label: "Таймер и лайки/дизлайки" },
  "error": { label: "Ошибка" },
  "self-cons": {label: "Мой аргумент против"},
  "pros": {label: "Аргумент за"},
  "cons": { label: "Аргумент против - фон" },
  "disabled": {
    label: "Текст disabled когда опрос закрыт",
  },
  "frame": {
    label: "Рамки и разделители",
  },
  "text-primary": { label: "Текст - основной" },
  "text-primary-dark": { label: "Текст - основной тёмный" },
  "text-background": { label: "Текст - фон" },
  "icon": { label: "Иконки" },

  // participant
  "text-primary-participant": {
    label: "Текст основной",
  },
  "text-primary-dark-participant": {
    label: "Текст тёмный",
  },
  "text-disabled-participant": {
    label: "Текст в состоянии неактивности",
  },
  "primary-participant": {
    label: "Основной цвет (Все кнопки, рамки у форм в активном состоянии)",
  },
  "primary-light-participant": {
    label:
      "Основной цвет - светлый (Вакантное место в распределении на группы, карточка с вопросом в сборе мнений)",
  },
  "primary-light-opaque-participant": {
    label:
      "Основной цвет - светлый непрозрачный (Подсветка своего вопроса или ответа для участников, подсветка селекта с выбором залов в Задай вопрос и Сбор мнений)",
  },
  "primary-dark-participant": {
    label:
      "Основной цвет - тёмный (Эксперт в разделении на группы, выбранная карточка с вопросом в Сборе мнений, лого Tellsy)",
  },
  "icon-participant": {
    label: "Иконки в appbar",
  },
  // brainstorm
  "brainstorm-main": { label: "Мозговой штурм - основной" },
  "brainstorm-accent": { label: "Мозговой штурм - акцент" },
  "active-user": { label: "Активный участник" },

  // charts
  "chart-primary": { label: "Цвет графиков" },
  "chart-background": { label: "Цвет фона графиков" },
  "chart-correct": { label: "Цвет графиков (правильный ответ)" },
  "chart-correct-opaque": {
    label: "Цвет графиков прозрачный (правильный ответ)",
  },
};

export const DEFAULT_TELLSY_LOGO_URL = "/assets/img/tellsy_logo.svg#TellsyLogo";
